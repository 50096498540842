<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent="" scrollable  max-width="550px">
                <v-card>
                    <v-card-title>{{ formTitle }}</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text style="height: auto">
                        <br>
                        <form-validation-messages :messages="apiValidationMessages"></form-validation-messages>
                        <form-submit-messages :messages="apiMessages" :errorMessages="apiErrorMessages"></form-submit-messages>
                        <v-form id="form_rfid_user" ref="form" v-model="valid" lazy-validation>
                        <!-- začetni datum -->
                        <v-menu
                            ref="start_date_menu"
                            :close-on-content-click="false"
                            v-model="start_date_menu"
                            :nudge-right="40"
                            :return-value.sync="restriction_start_date"
                            
                            transition="scale-transition"
                            offset-y
                            
                            min-width="290px"
                            >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                v-on="on"
                                    v-model="restriction_start_date_formatted"
                                    label="Veljavnost od"
                                    append-icon="mdi-calendar-today"
                                    readonly
                                    clearable
                                ></v-text-field>
                            </template>
                            <v-date-picker color="secondary" @input="startDateMenuSave(restriction_start_date)" v-model="restriction_start_date" no-title scrollable locale="sl-si">
                            </v-date-picker>
                        </v-menu>

                        <!-- končni datum -->
                        <v-menu
                            ref="end_date_menu"
                            :close-on-content-click="false"
                            v-model="end_date_menu"
                            :nudge-right="40"
                            :return-value.sync="restriction_end_date"
                            
                            transition="scale-transition"
                            offset-y
                            
                            min-width="290px"
                            >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                v-on="on"
                                    v-model="restriction_end_date_formatted"
                                    label="Veljavnost do"
                                    append-icon="mdi-calendar"
                                    readonly
                                    clearable
                                ></v-text-field>
                            </template>
                            <v-date-picker color="secondary" @input="endDateMenuSave(restriction_end_date)" v-model="restriction_end_date" no-title scrollable locale="sl-si">
                            </v-date-picker>
                        </v-menu>

                        <!-- tip uporabnika -->
                        <v-select
                            :items="userTypes"
                            label="Tip uporabnika"
                            item-text="name"
                            item-value="uuid"
                            v-model="user_type"
                            persistent-hint
                            required
                            :rules="user_type_rules"
                            no-data-text="Ni podatkov za prikaz"
                        ></v-select>

                        <v-select
                            :items="restrictionTypes"
                            label="Tip omejitve"
                            item-text="name"
                            item-value="id"
                            v-model="restriction_id"
                            persistent-hint
                        ></v-select>

                        <v-text-field  v-if="abonent()"
                            v-model="name"
                            label="Naziv"
                            required
                            clearable
                            append-icon="mdi-pencil"
                            :rules="(abonent()) ? name_rules : []"
                        ></v-text-field>


                        <div style="min-height:10px;"></div>

                        <!-- registrska številka -->
                        <v-text-field
                            v-model="registration_number_1"
                            label="Registrska številka"
                            append-icon="mdi-car"
                            readonly
                            filled
                        ></v-text-field>

                        <!--<div style="min-height:10px;"></div>-->
                        <v-textarea
                        outlined
                        name="input-7-4"
                        label="Opombe"
                        v-model="note"
                        counter
                        required
                        clearable
                        ></v-textarea>

                        <v-switch
                            v-model="registration_numbers_enabled"
                            :label="`Dovoli dostop: ${userRegistrationNumbersEnabled}`"
                            color="secondary"
                            hide-details
                        ></v-switch>

                        <div style="min-height:10px;"></div>
                        </v-form>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn :disabled="disableSubmit" :loading="loading" color="success" text @click="submitForm()"><v-icon left>mdi-arrow-right</v-icon>Shrani</v-btn>
                        <v-btn color="error" text  @click="cancel()"><v-icon left>mdi-close</v-icon>{{ cancelText }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import { formatDate, randomPinNumber } from '@/helpers/utilities.js'
import { EventBus } from '@/main.js'
const FormValidationMessages = () => import('@/components/FormValidationMessages.vue')
const FormSubmitMessages = () => import('@/components/FormSubmitMessages.vue')
import { getCurrentDate } from '@/helpers/utilities.js'

export default {
    name: 'add-user-dialog',
    components: {
        FormValidationMessages,
        FormSubmitMessages
    },

    data: () => ({
        valid: true,
        formTitle: "",
        dialog: false,
        item: null,
        loading:false,
        disableSubmit: false,
        cancelText: "Prekliči",
        name: null,
        email: null,
        email_notification: false,

        restriction_start_date_formatted: null,
        restriction_end_date_formatted: null,
        start_date_menu: false,
        end_date_menu:false,
        user_type: null,
        restriction_start_date: null,
        restriction_end_date: null,
        reservation: null,
        room_number: null,
        parcel_number: null,
        phone_number_1 : null,
        note: null,
        registration_number_1: null,
        registration_number_2: null,
        registration_numbers_enabled: true,
        restriction_id: null,
        restrictionTypes: [],
        userTypes: [],
        companies: [],

        selected_companies: [],

        company_id: null,

        phone_number_rules: [v => !!v || 'Polje za vnos telefonske številke je obvezno'],
        reservation_rules: [v => !!v || 'Polje za vnos rezervacije je obvezno'],
        name_rules: [v => !!v || 'Polje ime in priimek je obvezno'],
        email_rules: [v => !!v || 'Polje email je obvezno'],
        user_type_rules: [v => !!v || 'Polje za izbiro tip uporabnika je obvezno'],
        company_rules: [v => !!v || 'Polje za vnos podjetja je obvezno'],

        backendMessages: [],
        backendErrorMessages: [],
        backendValidationMessages:[]

    }),

    watch: {
        restriction_start_date (val) {
            this.restriction_start_date_formatted = formatDate(val)
        },

        restriction_end_date (val) {
            this.restriction_end_date_formatted = formatDate(val)
        },

        restriction_start_date_formatted(val) {
            if(val == null) {
                this.restriction_start_date = null
            }
        },

        restriction_end_date_formatted(val) {
            if(val == null) {
                this.restriction_end_date = null
            }
        },

        registration_number_1(value) {
            if(value != null) {
                //window.console.log(this.registration_number_1)
                this.registration_number_1 = this.registration_number_1.replace(/[^a-zA-Z0-9]/g, "");
                this.registration_number_1 = this.registration_number_1.toUpperCase();
            }
        },

        registration_number_2(value) {
            if(value != null) {
                //window.console.log(this.registration_number_1)
                this.registration_number_2 = this.registration_number_2.replace(/[^a-zA-Z0-9]/g, "");
                this.registration_number_2 = this.registration_number_2.toUpperCase();
            }
        },

        user_type: {
            immediate: true,

            handler(value) {
                if(value == "d092f743-f2d4-4797-a832-55acc926700e") { //apartma
                    this.company_id = null
                    this.email_notification = true
                } else {
                    this.email_notification = false
                }
            }
        }
    },

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        userRegistrationNumbersEnabled() {
            if(this.registration_numbers_enabled) {
                return 'DA'
            }

            return 'NE'
        },

        apiMessages() {
            return this.backendMessages;
        },

        apiErrorMessages() {
            return this.backendErrorMessages 
        },

        apiValidationMessages() {
            return this.backendValidationMessages
        },

        emailNotificationLabel() {
            if(this.email_notification) {
                return 'DA'
            }

            return 'NE'
        }
    },

    methods: {
        startDateMenuSave(value) {
            this.$refs.start_date_menu.save(value)
            this.start_date_menu = false
        },

        endDateMenuSave(value) {
            this.$refs.end_date_menu.save(value)
            this.end_date_menu = false
        },
        
        submitForm() {
            var vm = this
            //window.console.log(vm.registration_number_1)
            if(this.$refs.form.validate()) {
                vm.loading = true
                vm.backendValidationMessages = []
                vm.backendMessages = []
                vm.backendErrorMessages = []

                vm.$store.dispatch('CREATE_RFID_USER', {
                    user_type: vm.user_type,
                    company_id: vm.company_id,
                    restriction_id: vm.restriction_id,
                    reservation: vm.reservation,
                    room_number: vm.room_number,
                    parcel_number: vm.parcel_number,
                    phone_number_1: vm.phone_number_1,
                    name: vm.name,
                    email: vm.email,
                    email_notification: vm.email_notification,
                    registration_number_1: vm.registration_number_1,
                    registration_number_2: vm.registration_number_2,
                    registration_numbers_enabled: vm.registration_numbers_enabled,
                    restriction_start_date: vm.restriction_start_date,
                    restriction_end_date: vm.restriction_end_date,
                    note: vm.note
                })
                .then(response => {
                    //window.console.log(response)
                    vm.backendMessages.push('Uporabnik je bil uspešno vnesen v sistem.');
                    vm.disableSubmit = true
                    vm.cancelText = "Zaključi"

                })
                .catch(error => {
                    console.log('CREATE RFID USER error')
                    console.log(error)

                    if(error.response.status === 422) {
                        window.console.log(error.response.data.errors)
                        for (const property in error.response.data.errors) {
                            //window.console.log(`${property}: ${error.response.data.errors[property]}`);
                            var propertyData = error.response.data.errors[property];
                            //window.console.log(propertyData);
                            propertyData.forEach(message => {
                                vm.backendValidationMessages.push(message);
                            })
                        }
                    } else {
                        vm.backendErrorMessages.push('Ups... pri zapisovanju uporabnika je prišlo do napake. Poskusite ponovno.')
                    }

                })
                .finally(() => {
                    setTimeout(() =>{
                        vm.loading = false
                    },600)
                })
            }
        },

        cancel() {
            this.clearForm()
            this.item = null
            this.dialog = false
            this.restriction_start_date = getCurrentDate()
            this.restriction_end_date = getCurrentDate()
            EventBus.$emit('refresh_dt_events', null)
            
        },

        clearForm() {
            this.$refs.form.resetValidation();
            this.setDefaultFormValues()
            this.clearBackendMessages()
        },

        setDefaultFormValues() {
            this.name = null
            this.email = null,
            this.email_notification = false,
            this.registration_number_1 = null
            this.company_id = null
            this.restriction_id = null
            this.user_type = null
            this.selected_companies = []
            this.restriction_start_date = null
            this.restriction_end_date = null
            this.reservation = null
            this.room_number = null
            this.parcel_number = null
            this.phone_number_1 = null
            this.registration_number_2 = null
            this.restriction_id = null
            this.note = null
            this.registration_numbers_enabled = true

            if(this.userTypes.length > 0) {
                this.user_type = this.userTypes[0].uuid
            }
        },

        clearBackendMessages() {
            this.backendValidationMessages = []
            this.backendMessages = []
            this.backendErrorMessages = []
        },

        setFormTitle() {
            if(this.item != null && this.item != undefined) {
                if(this.item.device_id == 1) {
                   this.formTitle = 'Vhod: ' + this.item.registration_plate_number.toUpperCase() + " - " + this.item.event_timestamp
                   return
                }

                this.formTitle = 'Izhod: ' + this.item.registration_plate_number.toUpperCase() + " - " + this.item.event_timestamp
            }
        },

        abonent() {
            return true
        }
    },

    created() {
        var vm = this;
        EventBus.$on('ADD_USER_DIALOG', function(data) {
            vm.item = data
            vm.registration_number_1 = data.registration_plate_number
            vm.setFormTitle();
            vm.dialog = true;
        });
    },

    mounted() {
        this.$store.dispatch('PARAMETERS_DATA')
        .then(response => {
            //console.log(response.data)
            this.restrictionTypes = response.data.restrictionTypes
            this.userTypes = response.data.userTypes
            //this.companies = response.data.companies

            if(this.userTypes.length > 0) {
                this.user_type = this.userTypes[0].uuid
            }
        })
        .catch(error => {
            console.log("error")
            console.log(error)
        })

        this.restriction_start_date = getCurrentDate();
        this.restriction_end_date = getCurrentDate();
    },

    destroyed() {
        EventBus.$off('ADD_USER_DIALOG')
    }
}

</script>

<style scoped>

</style>